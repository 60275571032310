import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { CookiesProvider } from "react-cookie"
import { Page } from "components"
import { FormsProvider, LanguageProvider } from "providers"
// import { LanguageProvider } from "providers"

const PageTemplate = ({ data, location, pageContext }) => {
  return (
    <CookiesProvider>
      {/* @todo Remove LanguageProvider in favor of IntlProvider. */}
      <LanguageProvider lang={data.drupal.page.entityLanguage.id}>
        {/* <FormsProvider> */}
        <Page
          data={data}
          pageContext={pageContext}
          lang={data.drupal.page.entityLanguage.id}
        />
        {/* </FormsProvider> */}
      </LanguageProvider>
    </CookiesProvider>
  )
}

PageTemplate.propTypes = {}

export default PageTemplate

export const pageQuery = graphql`
  query page($id: String!, $language: Drupal_LanguageId!) {
    drupal {
      page: nodeById(id: $id, language: $language) {
        ... on Drupal_NodeTrustPage {
          nid
          uuid
          entityUuid
          entityLanguage {
            id
          }
          entityMetatags {
            ...Metatag
          }
          entityTranslations {
            ... on Drupal_NodeTrustPage {
              langcode {
                value
              }
              path {
                alias
              }
            }
          }
          title
          fieldContent {
            entity {
              entityUuid
              ...SectionDataQuery
            }
          }
          fieldHero {
            entity {
              entityUuid
              langcode {
                value
              }
              ...HeroAnimationDataQuery
              ...HeroDataQuery
            }
          }
        }
      }
    }
  }
`
